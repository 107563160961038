import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from './Layout';
import { LayoutRenderer } from '~/components/layouts/LayoutRenderer';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function DefaultPage({ data: { wpPage, wp } }) {
  let headerColor = 'default';
  if (wpPage && wpPage.postTypePagePreview?.headerColor) headerColor = wpPage.postTypePagePreview?.headerColor;

  let headerType = 'default';
  if (wpPage && wpPage.postTypePagePreview?.headerType) headerType = wpPage.postTypePagePreview?.headerType;

  let backgroundColour = 'default';
  if (wpPage && wpPage.postTypePagePreview?.backgroundColour) headerColor = wpPage.postTypePagePreview?.headerColor;

  let hideFooter = false;
  if (wpPage && wpPage.postTypePagePreview?.hideFooter) hideFooter = wpPage.postTypePagePreview?.hideFooter;


  return (
    <Layout
      wp={wp}
      page={wpPage}
      headerColor={headerColor}
      backgroundColour={backgroundColour}
      headerType={headerType}
      hideFooter={hideFooter}>
      {wpPage && (
        <Seo
          post={wpPage}
        />
      )}
      <LayoutRenderer
        prefix="Page_Posttypepage_Sections_Section_Components_"
        sections={wpPage?.postTypePage?.sections}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query Default($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wp {
      ...GeneratedWp
    }
  }
`;
